import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, tap, throwError, timeout } from 'rxjs';
import { URL } from '../../../core/configs/url';
import { EducationPOST } from '../../../core/models/education.interface';
import { AdditionalEducationPOST } from '../../../core/models/additionalEducation.interface';

@Injectable({
  providedIn: 'root'
})
export class CurriculumService {
  private timeoutRequisition:number = 90000;

  private user:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private experiencesSubject:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private coursesSubject:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private educationsSubject:BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  private experiences$:Observable<any> = this.experiencesSubject.asObservable();
  private educations$:Observable<any> = this.educationsSubject.asObservable();
  private courses$:Observable<any> = this.coursesSubject.asObservable();
  constructor(private httpClient:HttpClient) { }

  cachedExperiences(): Observable<any>{
    return this.experiences$;
  }

  cachedEducations(): Observable<any>{
    return this.educations$;
  }

  cachedCourses(): Observable<any> {
    return this.courses$;
  }

  getExperiencesByCode(filter:string, sort:string, updateExperiences:boolean = true):Observable<any>{
    const filterSearch = `code eq '${encodeURIComponent(filter)}'`;
    const sortSerch = '+sequence';
    const url = `${URL.curriculum.experiences}?filter=${filterSearch}&sort=${sortSerch}`;
    console.log('sort: '+ decodeURIComponent(sort))

    return this.httpClient.get<any>(`${url}`, { observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          console.log("RESPOSTA EXPERIENCES::::", response)
          // Verifica o status da resposta e emite um erro se não for 200
          if (response.status !== 200) {
            throw { message: 'erro ao consultar experiencias', errorDetails: response };
          }

          if(updateExperiences){
            this.experiencesSubject.next({'items': response.body.items});
          }
        }),
        map((response:any) => ({
          message: 'sucesso ao consultar experiencias',
          data: response.body
        })),
        catchError(error => {
          // Emite um erro que pode ser capturado pelo método `error` do subscribe
          return throwError(() => ({ message: 'erro ao consultar experiencias', errorDetails: error }));
        })
      );
  }

  getCoursesByCode(filter:string, sort:string, updateCourses:boolean = true):Observable<any>{
    const parameters = new HttpParams()
    .set('filter', `code eq '${encodeURIComponent(filter)}'`)
    .set('sort', sort);
    var params:string = '';
    params += `?page=1&pageSize=50&filter=code eq ${encodeURIComponent(filter)}&sort=${sort}`;

    return this.httpClient.get<any>(`${URL.curriculum.additionaleducations}${params}`, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          console.log("RESPOSTA CURSOS::::", response)
          // Verifica o status da resposta e emite um erro se não for 200
          if (response.status !== 200) {
            throw { message: 'erro ao consultar cursos', errorDetails: response };
          }

          if(updateCourses){
            this.coursesSubject.next({'items':response.body.items});
          }
        }),
        map((response:any) => ({
          message: 'sucesso ao consultar cursos',
          data: response.body
        })),
        catchError(error => {
          // Emite um erro que pode ser capturado pelo método `error` do subscribe
          return throwError(() => ({ message: 'erro ao consultar cursos', errorDetails: error }));
        })
      );
  }

  getEducationsByCode(filter:string, sort:string, updateEducations:boolean = true):Observable<any>{
    const parameters = new HttpParams()
    .set('filter', `code eq '${encodeURIComponent(filter)}'`)
    .set('sort', sort);
    var params:string = '';
    params += `?page=1&pageSize=100&filter=code eq ${encodeURIComponent(filter)}&sort=${sort}`;

    return this.httpClient.get<any>(`${URL.curriculum.educations}${params}`, { observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          console.log("RESPOSTA FORMACOES ::::", response)
          if (response.status !== 200) {
            throw { message: 'erro ao consultar formacoes', errorDetails: response };
          }

          if(updateEducations){
            this.educationsSubject.next(response.body)
          }
        }),
        map((response:any) => ({
          message: 'sucesso ao consultar formacoes',
          data: response.body
        })),
        catchError(error => {
          // Emite um erro que pode ser capturado pelo método `error` do subscribe
          return throwError(() => ({ message: 'erro ao consultar formacoes', errorDetails: error }));
        })
      );
  }

  createExperience(bodyRequest:Record<string,any>): Observable<any>{
    console.log("Body: ", bodyRequest)
    return this.httpClient.post<any>(`${URL.curriculum.experiences}`, bodyRequest, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          console.log("RESPOSTA EXPERIENCIA::::", response)
          if (response.status !== 200) {
            throw { message: 'erro ao criar experiencia', errorDetails: response };
          }
        }),
        map((response:any) => ({
          message: 'sucesso ao criar experiencia',
          data: response.body
        })),
        catchError(error => {
          return throwError(() => ({ message: 'erro ao criar experiencia', errorDetails: error }));
        })
      );
  }

  createEducation(bodyRequest:EducationPOST): Observable<any>{
    //bodyRequest.fluigCode = 'fluigintegracao'; //FIXME: VERIFICAR PQ APENAS COM O fluigintegracao esta dando certo
    console.log("Body: ", bodyRequest)
    return this.httpClient.post<any>(`${URL.curriculum.educations}/${bodyRequest.code}`, bodyRequest, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          console.log("RESPOSTA FORMAÇÃO :::", response)
          if (response.status !== 200) {
            throw { message: 'erro ao criar formação', errorDetails: response };
          }
        }),
        map((response:any) => ({
          message: 'sucesso ao criar formação',
          data: response.body
        })),
        catchError(error => {
          return throwError(() => ({ message: 'erro ao criar formação', errorDetails: error }));
        })
      );
  }

  createAdditionalEducations(bodyRequest:AdditionalEducationPOST): Observable<any>{
    //bodyRequest.fluigCode = 'fluigintegracao'; //FIXME: VERIFICAR PQ APENAS COM O fluigintegracao esta dando certo
    console.log("Body: ", bodyRequest)
    return this.httpClient.post<any>(`${URL.curriculum.additionaleducations}/${bodyRequest.code}`, bodyRequest, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          console.log("RESPOSTA CURSO :::", response)
          if (response.status !== 200) {
            throw { message: 'erro ao criar curso', errorDetails: response };
          }
        }),
        map((response:any) => ({
          message: 'sucesso ao criar curso',
          data: response.body
        })),
        catchError(error => {
          return throwError(() => ({ message: 'erro ao criar curso', errorDetails: error }));
        })
      );
  }

  deleteExperience(idExperience:string): Observable<any>{
    return this.httpClient.delete<any>(`${URL.curriculum.experiences}/${idExperience}`, { observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          console.log("RESPOSTA DO DELETAR EXPERIENCIAS::::", response)
          // Verifica o status da resposta e emite um erro se não for 200
          if (response.status !== 200) {
            throw { message: 'erro ao deleter experiencia '+idExperience, errorDetails: response };
          }
        }),
        map((response:any) => ({
          message: 'sucesso ao deletar experiencia '+idExperience,
          data: response.body
        })),
        catchError(error => {
          // Emite um erro que pode ser capturado pelo método `error` do subscribe
          return throwError(() => ({ message: 'erro ao deletar experiencia '+idExperience, errorDetails: error }));
        })
      );
  }

  updateExperience(idExperience:string, bodyRequest:Record<string,any>): Observable<any>{
    return this.httpClient.put<any>(`${URL.curriculum.experiences}/${idExperience}`, bodyRequest, {observe: 'response'})
      .pipe(
        timeout(this.timeoutRequisition),
        tap((response:any) => {
          console.log("RESPOSTA EXPERIENCIA::::", response)
          if (response.status !== 200) {
            throw { message: 'erro ao atualizar experiencia', errorDetails: response };
          }
        }),
        map((response:any) => ({
          message: 'sucesso ao atualizar experiencia',
          data: response.body
        })),
        catchError(error => {
          // Emite um erro que pode ser capturado pelo método `error` do subscribe
          return throwError(() => ({ message: 'erro ao atualizar experiencia', errorDetails: error }));
        })
      );
  }

  clearSubject(): void {
    this.experiencesSubject.next(undefined);
    this.coursesSubject.next(undefined);
    this.educationsSubject.next(undefined);
  }
}
